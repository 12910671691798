<template>
  <v-card class="ma-2">
    <v-expansion-panels variant="accordion" v-model="panels">
      <v-expansion-panel title="個別追加" :value="true" class="pa-2">
        <v-col v-if="err" cols="12" style="background-color: lightpink;">
          <v-row v-for="(val, key) in err" :key="key">
            <v-text>{{ val }}</v-text>
          </v-row>
        </v-col>
        <v-expansion-panel-text>
          <v-sheet class="pa-2">
            <v-text-field label="氏名"
            v-model="formDatas.name" 
            :rules="[required]"
            color="cyan-darken-1"
            prepend-icon="mdi mdi-account"
            clearable/>
            <v-text-field label="メールアドレス"
            v-model="formDatas.email"
            :rules="[required,emailRegex]"
            color="cyan-darken-1"
            prepend-icon="mdi mdi-email-outline"
            clearable/>
            <v-text-field type="date" label="開始日"
            v-model="formDatas.start_at" 
            :rules="[required]"
            clearable/>
            <v-row>
              <v-col cols="9">
              <v-select 
              :items="courses"
              item-title="course_name" item-value="id"
              v-model="selectedCourse"
              :rules="[required]"
              label="受講コース" 
              clearable/>
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="formDatas.unlocked" label="模試アンロック"></v-checkbox>
              </v-col>
            </v-row>
          </v-sheet>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel title="一括追加" :value="false" class="pa-2">
        <v-expansion-panel-text>
          <v-select 
          :items="courses"
          item-title="course_name" item-value="id"
          v-model="selectedCourse"
          label="コース選択" />
          <v-file-input label="CSV import" accept="text/csv" @change="fileInput($event, consts.headers)" @click:clear="resetInputs" />
          <v-data-table :headers="consts.headers" :items="inputs">
            <template #[`item.start_at`]="{ item }">
              <v-text-field type="date" v-model="item.value.start_at" />
            </template>
            <template #[`item.unlocked`]="{ item }">
              <v-checkbox v-model="item.value.unlocked" />
            </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="space-around" class="pa-2 ma-4">
      <v-btn @click="closeModal">閉じる</v-btn>
      <v-btn color="warning" @click="sendData">登録</v-btn>
    </v-row>
  </v-card>
</template>
<script setup>
import { ref, onBeforeMount, defineEmits } from 'vue';
import { useValidation } from '@/plugins/validation';
import { useGetCourse } from '@/plugins/apis/useGetCourses';
import { useStudentRegister } from '@/plugins/apis/admins/useStudentRegister';
import { useFileReader } from '@/plugins/lib/admins/useFileReader'

import consts from '@/plugins/consts'

const { required, emailRegex, validation } = useValidation();
const { courses, getCourses }   = useGetCourse();
const { formDatas, selectedCourse, resetDatas, sendNewStudentData } = useStudentRegister();
const { inputs, fileInput, resetInputs } = useFileReader();

const panels = ref(true);

onBeforeMount( async () => { await getCourses(); })

let err = ref();
const sendData = () => {
  if(panels.value){
    let data = {
      'name':formDatas.name,
      'email':formDatas.email,
      'start_at':formDatas.start_at,
      'selectedCourse':selectedCourse.value
    }
    const validated = validation(data);
    if(validated == true){
      sendNewStudentData([formDatas]);
      err.value = null;
    }else{
      err.value = validated;
    }
  }else{
    sendNewStudentData(inputs);
  }
}
const emit       = defineEmits(['toggleModal'])
const closeModal = () => {
  resetDatas();
  emit('toggleModal');
}
</script>
