<template>
  <v-card class="ma-2">
    <v-expansion-panels variant="accordion" v-model="panels">
      <v-expansion-panel title="個別追加" :value="true" class="pa-2"  @click="typeSingle()">
        <v-col v-if="err" cols="12" style="background-color: lightpink;">
          <v-row v-for="(val, key) in err" :key="key">
            <v-text>{{ val }}</v-text>
          </v-row>
        </v-col>
        <v-expansion-panel-text>
          <v-sheet class="pa-2">
            <v-row justify="space-around">
              <v-col cols="3">
                <v-text-field label="章番号" 
                v-model="questionForms.chapter"
                :rules="[required]"
                color="cyan-darken-1"
                clearable/>
              </v-col>
              <v-col cols="3">
                <v-text-field label="解答" 
                v-model="questionForms.correct" 
                :rules="[required]"
                color="cyan-darken-1"
                clearable/>
              </v-col>
              <v-col cols="3">
                <v-checkbox label="模試使用" v-model="questionForms.for_exam" />
              </v-col>
            </v-row>
            <v-text-field label="問題文" 
              v-model="questionForms.question" 
              :rules="[required]"
              color="cyan-darken-1"
              clearable/>
            <div v-if="props.oldData">
              <v-row v-if="props.oldData.image" justify="space-around">
                <v-col cols="4">
                  <img :src="props.oldData.image_url" alt="問題画像" style="width: 10rem; height: auto;">
                </v-col>
                <v-col cols="4">
                  <v-checkbox label="画像削除" v-model="delete_image"/>
                </v-col>
              </v-row>
            </div>
            <v-file-input label="画像" v-model="image" />
            <v-text-field label="解説文" 
              v-model="questionForms.explanation" 
              :rules="[required]"
              color="cyan-darken-1"
              clearable/>
            <v-row v-for="(val, key) in selects" :key="key" align-content="center">
              <v-text-field v-model="selects[key]" :label="'選択肢'+key" />
              <v-btn @click="choicesPlus" class="mx-2"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn @click="choicesMinus" class="mx-2"><v-icon>mdi-minus</v-icon></v-btn>
            </v-row>
          </v-sheet>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel title="一括追加" :value="false" class="pa-2" @click="typeMany()" v-if="!props.oldData">
        <v-expansion-panel-text>
          <v-file-input label="CSV import" accept="text/csv" @change="csvInput($event)" @click:clear="resetInputs" />
          <v-data-table :headers="headers" :items="inputs">
            <template #[`item.for_exam`]="{ item }">
              <v-checkbox v-model="item.value.for_exam" />
            </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="space-around" class="pa-2 ma-4">
      <v-btn v-if="!props.oldData" @click="closeModal">閉じる</v-btn>
      <v-btn color="warning" @click="sendQuestionDatas">登録</v-btn>
    </v-row>
  </v-card>
</template>
<script setup>
import { ref, defineProps, defineEmits, onBeforeMount } from 'vue';
import { useValidation } from '@/plugins/validation';
import { useQuestionsForm } from '@/plugins/lib/admins/useQuestionsForm'
import { useFileReader } from '@/plugins/lib/admins/useFileReader'
import { useGetQuestionfFileColumns } from '@/plugins/lib/admins/useGetFileColumns'
import { useQuestionRegister } from '@/plugins/apis/admins/useQuestionRegister';
import { useMessage } from '@/plugins/lib/useMessage'

const { required, questionValidation } = useValidation();
const { inputs, fileInput,resetInputs } = useFileReader();
const { questionForms, selects, choicesPlus, choicesMinus } = useQuestionsForm();
const { headers, getColumns, selectToObj, deleteRowSelects } = useGetQuestionfFileColumns();
const { type, image, delete_image, sendQuestion, editQuestion } = useQuestionRegister();
const { message } = useMessage();

const panels = ref(true);
const props  = defineProps(['course','oldData'])

const emit       = defineEmits(['toggleModal'])
const closeModal = () => {
  resetInputs();
  emit('toggleModal');
}
onBeforeMount( () => {
  if(props.oldData){
    const data = {
      'chapter': props.oldData.chapter.order, 
      'explanation': props.oldData.explanation, 
      'correct': String(props.oldData.corrects[0]), 
      'for_exam': props.oldData.for_exam, 
      'question': props.oldData.question, 
      'selectsJson': props.oldData.selects_json, 
    }
    questionForms.chapter = props.oldData.chapter.order;
    questionForms.explanation = data.explanation;
    questionForms.correct = data.correct;
    questionForms.for_exam = data.for_exam;
    questionForms.question = data.question;
    Object.keys(data.selectsJson).forEach((key) => {
      questionForms.selectsJson[key] = data.selectsJson[key];
    });
  }
  })  
const csvInput = ($event) => {
  getColumns($event);
  fileInput($event, headers.value);
}
const typeSingle = () => {
  type.value = true
}
const typeMany = () => {
  type.value = false
}

let err = ref();
const sendQuestionDatas = async () => {
  err.value = [];
  let datas  = panels.value ? [questionForms] : selectToObj(inputs.value);
  const data = deleteRowSelects(datas);
  const validated = questionValidation(data);
  if(validated ==true && props.course){
    err.value = null;
    try {
      if(props.oldData){
        await editQuestion({ 'course_id': props.course, 'questions': data }, props.oldData.id)
        message('問題を編集しました');
      }else{
        await sendQuestion({ 'course_id': props.course, 'questions': data });
        message('問題を登録しました');
      }
    } catch (error) {
      if(error.response.status == 599) {
        console.log(error.response.data.message)
      }
    }
  }else{
    if(!props.course){
      err.value.push('コースを選択してください');
    }
    Object.keys(validated).forEach(function(index){
    err.value.push(validated[index]);
    });
  }
}
</script>