<template>
    <v-col cols="12">
        <v-row v-for="(val, key) in props.list" :key="key" justify="apace-around" class="mt-1 md-1">
            <v-col cols="12">
                <v-row class="pa-3">
                    <v-col>
                        <v-text style="font-size: 1.2rem; margin-left: 0.5rem;">{{ key + 1 }}問目</v-text>
                    </v-col>
                    <v-col>
                        <v-text v-if="val.for_exam">模試利用あり</v-text>
                        <v-text v-if="!val.for_exam" >模試利用なし</v-text>
                    </v-col>
                    <v-col>
                        <v-btn color="secondary" @click.stop="val.index = true" style="margin-left: 10rem;">編集</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <div class="questionText">
                    <v-divider />
                    <p style="font-size: 0.95rem;">{{ val.question }}</p>
                    <v-divider />
                    <img v-if="val.image" :src="val.image_url" alt="問題画像" style="width: 15rem; height: auto; margin-top: 1rem;">
                </div>
                <v-row class="pa-3">
                    <v-text style="font-size: 0.8rem">正答は<span style="color: orange;">オレンジ</span></v-text>
                    <v-col v-for="(v,k) in val.selects_json" :key="k" cols="12">
                        <v-sheet color="grey-lighten-3" rounded="rounded" :class="{'correct': isInArray(k,val.corrects)}">
                            <v-text class="pa-12">
                                {{ v }}
                            </v-text>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-divider />
                <v-text>{{ val.explanation }}</v-text>
                <v-divider />
            </v-col>
            <v-dialog v-model="val.index" persistent>
                <v-sheet>
                    <questionRegister :course="props.course" :oldData="val"/>
                    <v-row justify="space-around" class="pa-2 ma-4">
                        <v-btn @click.stop="val.index = false">閉じる</v-btn>
                    </v-row>
                </v-sheet>
            </v-dialog>
        </v-row>
    </v-col>
</template>
<script setup>
import { defineProps } from 'vue';
import questionRegister from '@/components/modules/admin/questionRegister.vue';

const props = defineProps(['list','course']);
const isInArray = (key, array) => { 
  if(key == array){
    return true;
  }else{
    return false;
  }
  }

</script>