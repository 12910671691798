import { ref, computed, reactive } from 'vue';

interface PracticeParams {
  amount: Number,
  modeSelect: String,
  chapter: String | undefined,
}

export const usePracticeMode = () => {
  const isExpand   = ref(false);
  const parameters:PracticeParams = reactive({'amount': 10, 'modeSelect': 'random', 'chapter': undefined})

  const selectChanged = computed(() => {
    return parameters.modeSelect == 'chapter' ? false : true;
  });

  return {
    isExpand,
    parameters,
    selectChanged
  }
}