import { ref, reactive, toRaw } from 'vue';
import consts from '@/plugins/consts'
import { examHistoryResponse, graphData } from '@/plugins/lib/Interface/examHistories'


export const useHistoryGraph = () => {
  const datas:graphData = reactive({'labels': consts.graphData.labels, 'datasets': consts.graphData.datasets })
  const isLoaded = ref(false);
  
  const graphData = async (resp: examHistoryResponse) => {
    const length = Object.keys(resp).length >=2 ? 2 : Object.keys(resp).length;

    for(let key=0; key<=length-1; key++) {
      const dataArray:Array<number> = [];
      datas.labels = toRaw(Object.keys(resp[key].correctCount))
      
      datas.labels.forEach((value:string, index:number)=> {
        dataArray.push(parseFloat(resp[key].correctCount[index + 1].rate))
      })
      datas.datasets[key].data = dataArray;
    }
    isLoaded.value = true;
  }
  return { datas, isLoaded, graphData }
}