import { ref } from 'vue';
import axios from '@/plugins/axios'

export const useQuestionRegister = () => {
  const type = ref(true);
  const image = ref();
  const delete_image = ref(false);
  const sendQuestion = async (data:object) => {
    const image_data = image.value
    const headers = {
      headers: {
      'content-type': 'multipart/form-data',
      },
    };
    const resp = await axios.post('/questions/create', data);
    if(type.value && image_data != undefined){
        const question_id = resp.data
        axios.put('/question/create/image/'+question_id, image_data,headers)
      }
  }
  const editQuestion = async (data:object, question_id:Number) => {
    const image_data = image.value
    const headers = {
      headers: {
      'content-type': 'multipart/form-data',
      },
    };
    const resp = await axios.put('/question/edit/'+question_id, data);
    if(image_data != undefined && !delete_image.value){
      axios.put('/question/create/image/'+question_id, image_data,headers)
    }else if(delete_image.value){
      axios.put('/question/image/delet/'+question_id)
    }
  }

  return { type, image, delete_image, sendQuestion, editQuestion }
}