import axios from '@/plugins/axios';
import router from '@/plugins/router'
import { ref } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';
import { loginResponse } from '@/plugins/lib/Interface/loginResponse';
import { useMessage } from '@/plugins/lib/useMessage'


export const useAuthorization = () => {
  const { message } = useMessage();

  const { useLogin, useLogout, adminStudent } = useStoreAuth();
  const email           = ref();
  const password        = ref();
  const passwordConfirm = ref();
  const isAdmin         = ref();

  if(location.pathname == '/admin/login') isAdmin.value = true;
  if(location.pathname == '/login') isAdmin.value = false;
  
  const login = async () => {
    try {
      const result = await axios.post('/login', {'email': email.value, 'password': password.value});
      const loginData: loginResponse = result.data;
      message('ログインしました');
      useLogin(loginData);
      if(loginData.user.is_admin && location.pathname == '/admin/login'){
        router.push('/admin');
      }else if(loginData.user.is_admin && location.pathname == '/login'){
        adminStudent(true);
        router.push('/');
      }
      else{
        router.push('/');
      }
      return loginData;  
    } catch (err) {
      console.log(err)
      if (err == 400) { message('ログイン内容をご確認ください') }
    }
  }

  const logout = async () => {
    await axios.post('/logout');
    useLogout();
    message('ログアウトしました')
  }

  const passwordResetRequest = async () => {
    try{
      await axios.post('/password/reset', {'email': email.value});
      message('メールを送信しました')
    }catch(err){
      if (err == 400) { message('メールアドレスをご確認ください') }
    }
  }

  const passwordReset = async (token:string) => {
    try{
      const result = await axios.post('password/reset/'+token, {'password': password.value});
      console.log(result);
      message('パスワードを変更しました');
      if(result.data == true) router.push('/admin/login');
      if(result.data == false) router.push('/login');
    }catch(err){
      if (err == 400) { message('変更できませんでした')}
    }
  }

  const passwordUpdate = async () => {
    const { user }  = useStoreAuth();
    try{
      await axios.put('/password/update/' +user.id, {'password': password.value});
      message('変更しました')
    }catch (err){
      if (err == 400) { message('変更できませんでした') }
    }
  }

  return {
    email,
    password,
    passwordConfirm,
    isAdmin,
    login,
    logout,
    passwordResetRequest,
    passwordReset,
    passwordUpdate
  }
}