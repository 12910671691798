<template>
  <v-container>
    <v-text style="font-size:1.3rem;" >{{ props.name }}</v-text>
    <v-row>
      <v-col>
        <RadarChart v-if="isLoaded" :data="toRaw(datas)" />
        <lineChart v-if="isLoad" :data="rate_data"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="warning" @click="changeActive()">退職済に変更</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { onBeforeMount, toRaw, defineProps } from 'vue';
import { useHistoryGraph } from '@/plugins/lib/useHistoryGraph';
import RadarChart from '@/components/modules/students/radarChart.vue';
import examAPIs from '@/plugins/apis/practiceExam';
import { useHistoryRate } from '@/plugins/lib/useHistoryRate';
import lineChart from '@/components/modules/students/lineChart.vue';
import { useChangeActive } from '@/plugins/apis/admins/useChangeActive';

const { datas, isLoaded, graphData } = useHistoryGraph();
const { rate_data, isLoad, getRateData } = useHistoryRate();
const { notActive } = useChangeActive();

const props  = defineProps(['id', 'name']);
onBeforeMount(
  async () => {
    const resp = await examAPIs.getExamHistory(props.id);
    graphData(resp);
    getRateData(resp);
  }
)
const changeActive = async () => {
  await notActive(props.id,true);
}

</script>