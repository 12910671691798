<template>
  <v-col cols="12">
    <v-expansion-panels class="md:mx-4 my-4" v-model="isExpand">
      <v-expansion-panel>
        <v-expansion-panel-title class="select-none">練習問題モード</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-col>
            <v-select
              v-model="parameters.amount"
              density="compact"
              label="出題数を選択"
              item-title="text"
              item-value="value"
              :items="$consts.questionsAmounts" />
            <v-radio-group class="mt-2" v-model="parameters.modeSelect" @change="selectChanged">
              <v-radio label="ランダム出題" :value="`random`" />
              <v-radio label="間違えた問題から" :value="`missed`" />
              <v-radio label="特定の章から" :value="`chapter`" />
            </v-radio-group>
            <v-select
              v-model="parameters.chapter"
              density="compact" 
              :disabled="selectChanged" 
              label="章を選択"
              item-title="chapter_name"
              item-value="id"
              :items="props.course.chapterCourse" />
            <v-row justify="space-around">
              <v-btn @click="goPractice" color="secondary">練習問題へ</v-btn>
            </v-row>
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>
<script setup>
import { defineProps } from 'vue';
import router from '@/plugins/router';
import { useMessage } from '@/plugins/lib/useMessage';
import { usePracticeMode } from '@/plugins/lib/usePracticeMode';
const { isExpand, parameters, selectChanged } = usePracticeMode();

const { message } = useMessage()

const props  = defineProps(['course']);

const goPractice = () => {
  if(parameters.modeSelect == 'chapter' && !parameters.chapter) {
    message('章を選択してください');
    return false;
  }
  router.push({ name: 'Practice', query: { ...parameters }})
}
</script>

