import { ref } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';

interface AnsList {
  [key: number]: Array<Number>;
}

interface QuestionsLists {
  [key: number]: QuestionsInformation
}

interface UserAnswersList {
  choices: Array<Number>;
  order: Array<Number>;
  question_id: Number;
}

interface ExamAnswerParameters {
  user_id: Number;
  course_id: Number;
  duration: Number;
  answers: Array<UserAnswersList>;
}

interface QuestionsInformation {
  id: Number;
  chapter_id: Number;
  corrects_csv: String;
  explanation: String;
  name: String;
  question: String;
  selects: Array<Number>;
  selects_json: Map<Number, String>;
  selects_num: Number;
}

export const useQuestionSwitcher = () => {
  const progress   = ref(0);
  const answerList = ref(<AnsList>{});
  const statements = ref();

  const storeAnswers = () => {
    answerList.value[progress.value] = statements.value.getValue();
  }

  const switchQuestion = (next=true) => {
    storeAnswers();
    progress.value = next ? progress.value + 1 : progress.value - 1;
  }
  
  const finishExam = (questions:QuestionsLists,time:number) => {
    storeAnswers();
    const { user, course }   = useStoreAuth();
    const parameters = <ExamAnswerParameters>{};
  
    parameters['answers'] = [];
    const listKeys: number[] = Object.keys(answerList.value).map(Number);
    listKeys.forEach((key)=> {
      const element        = answerList.value[key]
      const questionParams = <UserAnswersList>{};
      let choices          = <Array<Number>>[];
  
      if(typeof element[0] == "number") {
        element.forEach((elem:Number) => choices.push(elem));
      } else {
        choices = [ 0 ];
      }
  
      questionParams['question_id'] = questions[key].id;
      questionParams['choices']     = choices;
      questionParams['order']       = questions[key].selects;
  
      parameters['answers'].push(questionParams);
    })
    parameters['user_id']   = user.id;
    parameters['course_id'] = course.id;
    parameters['duration']  = time;
  
    return parameters;
  }
  return {
    progress, answerList, statements,
    storeAnswers, switchQuestion, finishExam
  }
}