const consts = {
  options: {
    responsive: false,
    maintainAspectRatio: false
  },
  graphData: {
    labels: [],
    datasets: [
      {
        label: '前回結果',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: []
      },
      {
        label: '前々回結果',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        data: []
      }
    ]
  },

  headers: [
    { title: "名前", align:"left", sortable: false, key: "name" },
    { title: "メールアドレス", align:"left", key: "email" },
    { title: "開始日", align:"left", key: "start_at" },
    { title: "模試アンロック", align:"left", key: "unlocked" },
  ],

  booleTypeHeader: ['unlocked', 'for_exam'],
  fixedHeader: [ 'chapter',  'question','for_exam', 'correct',  'explanation'],
  
  questionsAmounts: [
    { text: '10問', value: 10 },
    { text: '20問', value: 20 },
    { text: '30問', value: 30 },
    { text: '40問', value: 40 },
    { text: '50問', value: 50 }
  ],

  lineGraphData: {
    labels: [],
    datasets: [
    {
      label: '正答率',
      backgroundColor: 'rgba(160,216,239,1)',
      data: []
    }
    ]
  }
}

export default consts