import { reactive } from 'vue';

interface chapterForms {
  [key: number] : {
    chapter_name:string
    order: number
    count: string
    rate: number
  };
}

export const useChapterForm = () => {
  const chapterForms:chapterForms = reactive({
    1:{'chapter_name': '', 'order': 1, 'count': '', 'rate': 0}
  })
  const choicesPlus  = () => { 
    const maxKey = Object.keys(chapterForms).length + 1;
    chapterForms[maxKey] = {'chapter_name': '', 'order': maxKey, 'count': '', 'rate': 0};
    }
  const choicesMinus = () => {
    const maxKey = Object.keys(chapterForms).length;
    delete chapterForms[maxKey];
  }
  const changeChapterData = (type: string, question_count: string) => {
    Object.keys(chapterForms).forEach(function(index: any){
      if(type === 'rate'){
        let count = 0;
        count = Number(chapterForms[index].rate)*Number(question_count)/100;
        chapterForms[index].count = String(Math.floor(count));
      }else if(type === 'count'){
        let rate = 0;
        rate = Number(chapterForms[index].count)*100/Number(question_count);
        chapterForms[index].rate = Number(rate.toFixed(2));
      }
    })
  }

  return {
    chapterForms, choicesPlus, choicesMinus, changeChapterData
  }
}
