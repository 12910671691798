<template>
  <v-container>
    <v-layout v-if="!isStart">
      <examStart @examStart="examStarter(course.limit)"/>
    </v-layout>
    <v-layout v-else-if="Object.keys(questions).length && !Object.keys(examResultR).length">
      <v-col cols="12">
        <questionStatement 
          ref="statements" 
          :question="questions[progress]" 
          :answerLists="answerList[progress]"
          :stopped="stopped"
          />
        <v-sheet>
          <v-row justify="space-around" class="mt-3 pa-2" v-if="!stopped">
            <v-btn @click="switchQuestion(false)" :disabled="progress == 0">前の問題へ</v-btn>
            <v-btn @click="switchQuestion" :disabled="progress == questions.length - 1">次の問題へ</v-btn>
          </v-row>
          <v-row justify="space-around" class="mt-3 pa-2" v-if="stopped">
            <v-text>制限時間が経過しました。模試を終了します。</v-text>
            <v-btn @click="sendExam" color="warning">結果を見る</v-btn>
          </v-row>
          <v-row class="ma-2 mt-5" justify="center" v-if="progress == questions.length - 1" >
            <v-divider />
            <v-btn 
            @click="sendExam" 
            color="warning"
            class="mt-3"
            >模試を終了する</v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-layout>
    <v-layout v-else-if="Object.keys(examResultR).length">
      <examResult :id="examResultR.id" />
    </v-layout>
    <circularLoading v-else />
  </v-container>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue';
import { useQuestionSwitcher } from '@/plugins/lib/useQuestionSwitcher';
import { useExam } from '@/plugins/lib/useExam';
import { useStoreAuth } from '@/plugins/pinia';

import questionStatement from '@/components/modules/students/questionStatement.vue';
import examStart from '@/components/modules/students/exams/examStart.vue';
import examResult from '@/components/modules/students/exams/examResult.vue';
import examAPIs from '@/plugins/apis/practiceExam';
import circularLoading from '@/components/modules/common/circularLoading.vue';

const { progress, answerList, statements, switchQuestion, finishExam } = useQuestionSwitcher();
const { isStart, examStarter, stop, stopped } = useExam(); 
const { course } = useStoreAuth();

let questions     = [];
const examResultR = ref([]);
const isEditing   = ref(false);

onBeforeMount(
  async () => {
    questions = await examAPIs.getExam(course.id);
    window.addEventListener("beforeunload", preventNav)
  }
);

const preventNav = (e) => {
  if(isEditing.value) return;
  e.preventDefault();
  e.returnValue = "check";
}

const sendExam = async () => {
  const time = stop();
  const parameters  = finishExam(questions,time);
  examResultR.value = await examAPIs.sendExam(course.id, parameters);
  isEditing.value   = true;
}
</script>
<style>
.questionText {
  margin: 15px;
}
</style>