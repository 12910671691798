import axios from '@/plugins/axios'
import { ref } from 'vue';

export const useCourseRegister = () => {
  const course_id = ref();
  const sendCourse = async (data:object) => {
      const resp = await axios.post('/courses', data);
      course_id.value = resp.data.id;
  }

  return { sendCourse, course_id }
}