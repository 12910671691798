<template>
  <v-container>
    <v-sheet class="ma-2">
      <v-row justify="space-between">
        <v-col cols="10" align="left">
          <v-text style="font-size:1.3rem; text-align: left;">管理者管理</v-text>
        </v-col>
        <v-col cols="2">
          <v-btn color="secondary" @click="toggleModal">管理者追加</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog v-model="isShow" persistent>
      <adminRegister @toggleModal="toggleModal" />
    </v-dialog>
    <v-table>
        <thead>
          <tr>
            <th>名前</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, key) in list" :key="key">
            <td>{{ val.name }}</td>
            <td><v-btn color="warning" @click="notActive(val.id,false)">退職済に変更</v-btn></td>
          </tr>
        </tbody>
      </v-table>
  </v-container>
</template>
<script setup>
import { onBeforeMount } from 'vue';
import adminRegister from '@/components/modules/admin/adminRegister.vue';
import { useUserList } from '@/plugins/apis/admins/useUserList';
import { useToggleModal } from '@/plugins/lib/useToggleModal';
import { useChangeActive } from '@/plugins/apis/admins/useChangeActive';

const { list, getAdminList} = useUserList();
const { isShow, toggleModal } = useToggleModal();
const { notActive } = useChangeActive();

onBeforeMount( async () => { 
  await getAdminList();
})
</script>