import { ref, reactive } from 'vue';

interface selects {
  [key: number] : string;
}

export const useQuestionsForm = () => {
  const selects:selects = reactive({1: '', 2: '', 3: '', 4: '' });
  const questionForms = reactive({
    'chapter': '', 'explanation': '', 'correct': '', 'for_exam': true, 'question': '', 'selectsJson': selects, 'image':''
  })

  const choicesPlus  = () => { 
    const maxKey = Object.keys(selects).length + 1;
    selects[maxKey] = '';
    }
  const choicesMinus = () => {
    const maxKey = Object.keys(selects).length;
    delete selects[maxKey];
  }
  
  return {
    questionForms, selects, choicesPlus, choicesMinus
  }
}