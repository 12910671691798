import { reactive } from 'vue';
import { item, value } from '@/plugins/lib/Interface/bookmarks'
import axios from '@/plugins/axios'

export const useBookmarks = () => {
  const bookmarkParam = reactive({'user_id': '', 'question_id': ''});
  
  const sendBookmark = async () => {
    const resp = await axios.post('/bookmarks/' + bookmarkParam.user_id, { ...bookmarkParam })
    return resp.data;
  }

  const getBookmarks = async (user_id: number) => {
    const result = await axios.get('/bookmarks/' + user_id)
    return moldBookmarksRecord(result.data);
  }

  const getMissList = async (user_id:number) => {
    const result = await axios.get('bookmarks/missed/'+user_id);
    return moldBookmarksRecord(result.data);
  }

  const moldBookmarksRecord = (data:any) => {
    const array: Array<item> = [];
    
    data.map(function(value:value){
      let item = <item>{};
      item = {
        'chapter_name': value.question.chapter.chapter_name,
        'miss_list': value
      }
      array.push(item);
    })
    
    const result = array.reduce((group:any, p:item) => {  
      group[p.chapter_name] = group[p.chapter_name] ?? [];
      group[p.chapter_name].push(p.miss_list);
      return group;
    }, {});

    return result;
  }

  return { bookmarkParam, sendBookmark, getBookmarks, getMissList }
}