import { ref } from 'vue';

export const useExam = () => {
  const isStart     = ref(false);
  const stopped     = ref(false);
  let count = 0;
  const time = ref();
  const countUp = () => {
    count++;
  }

  const examStarter = (timeLimit:number) => {
    isStart.value = true;
    time.value = setInterval(countUp, 1000);
    const limit = (ms:number) => new Promise(
      resolve => setTimeout(resolve, ms)
      );
    limit(timeLimit*1000)
    .then(() => clearInterval(time.value))
    .then(() => stopped.value = true );
  }
  const stop = () => {
    clearInterval(time.value);
    return count;
  }
  return {
    isStart, examStarter, stop, stopped
  }
}