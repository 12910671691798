<template>
  <v-col cols="12">
    <v-expansion-panels class="md:mx-4 my-4" v-model="isExpand">
      <v-expansion-panel v-for="(val, key) in props.list" :key="key">
        <v-expansion-panel-title class="select-none">{{ key }}</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row justify="space-around" v-for="(value,index) in val" :key="index" style="font-size: 0.8rem;">
            <v-col>
              <v-text>{{ value.question.question }}</v-text>
            </v-col>
            <v-col>
              <v-text>間違えた回数:{{ value.miss_counts }}回</v-text>
              <v-btn color="secondary" @click.stop="value.id = true" style="font-size: 0.8rem;">解説</v-btn>
              <bookmarkButton :question="value.question" />
            </v-col>
            <v-dialog v-model="value.id">
              <v-sheet>
                <v-col class="pa-3">
                  <v-col cols="12" style="text-align: center;">
                    <v-text style="font-size: 1.0rem;">-問題-</v-text>
                  </v-col>
                  <v-col cols="12">
                    <v-text class="pa-2" style="font-size: 0.8rem;">{{ value.question.question }}</v-text>
                  </v-col>
                  <v-text style="font-size: 0.5rem;">正答は<span style="color: orange; font-size: 0.5rem;">オレンジ</span></v-text>
                  <v-col v-for="(v,k) in value.question.selects_json" :key="k" cols="12">
                    <v-sheet color="grey-lighten-3" rounded="rounded" :class="{'correct': selectAnswer(k, value.question.corrects[0])}">
                      <v-text class="pa-2" style="font-size: 0.8rem;">
                        {{ value.question.selects_json[k] }}
                      </v-text>
                    </v-sheet>
                  </v-col>
                  <v-col cols="12" style="text-align: center;">
                    <v-text style="font-size: 1.0rem;">-解説-</v-text>
                  </v-col>
                  <v-col cols="12">
                    <v-text class="pa-2" style="font-size: 0.8rem;">{{ value.question.explanation }}</v-text>
                  </v-col>
                </v-col>
                <v-row justify="space-around" class="pa-2 ma-4">
                <v-btn @click.stop="value.id = false">閉じる</v-btn>
              </v-row>
              </v-sheet>
            </v-dialog>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>
<script setup>
import { defineProps } from 'vue';
import { usePracticeMode } from '@/plugins/lib/usePracticeMode'
import bookmarkButton from '../practiceQuestion/bookmarkButton.vue';

const { isExpand } = usePracticeMode();
const props  = defineProps(['list']);

const selectAnswer = (key, array) => { 
  if(key == array){
    return true;
  }else{
    return false;
  }
  }
</script>