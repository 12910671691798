<template>
  <v-container>
    <v-text style="font-size:1.3rem;" v-if="isStudent && course">
        選択中コース:{{ course.course_name }}
      </v-text>
    <v-row justify="center" class="pa-2">
      <v-select 
      v-if="isStudent"
      class="pa-3"
      v-model="course_id"
      :items="courses"
      @update:modelValue="courseInfo"
      item-title="course_name" 
      item-value="id"
      label="コース選択" 
      />
      <v-text style="font-size:1.3rem;" v-else-if="!isAdmin">
        コース:{{ course.course_name }}
      </v-text>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <RadarChart v-if="isLoaded" :data="toRaw(datas)" />
        <lineChart v-if="isLoad" :data="rate_data"/>
      </v-col>
      <practiceModeSelects :course="course"/>
    </v-row>
    <v-row>
      <v-col>
        <router-link to="/missList"><v-btn color="success">間違えた問題</v-btn></router-link>
      </v-col>
      <v-col>
        <v-btn color="warning" @click="startExams">模試開始</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { onBeforeMount, toRaw } from 'vue';
import { storeToRefs } from 'pinia'
import router from '@/plugins/router';
import { useStoreAuth } from '@/plugins/pinia';
import { useGetCourse } from '@/plugins/apis/useGetCourses';
import examAPIs from '@/plugins/apis/practiceExam';
import { useHistoryGraph } from '@/plugins/lib/useHistoryGraph';
import { useHistoryRate } from '@/plugins/lib/useHistoryRate';
import RadarChart from '@/components/modules/students/radarChart.vue';
import lineChart from '@/components/modules/students/lineChart.vue';
import practiceModeSelects from '@/components/modules/students/practiceQuestion/practiceModeSelects.vue';

const { isAdmin, isStudent, course  } = storeToRefs(useStoreAuth());
const { courses, course_id, getCourses, courseInfo } = useGetCourse();
const { user } = useStoreAuth();
const { datas, isLoaded, graphData } = useHistoryGraph();
const { rate_data, isLoad, getRateData } = useHistoryRate();

onBeforeMount(
  async () => {
    const resp = await examAPIs.getExamHistory(user.id);
    graphData(resp);
    getRateData(resp);
    getCourses();
  }
)

const startExams = () => router.push('/exam');
</script>