import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useStoreAuth } from '@/plugins/pinia'
import router from '@/plugins/router'

const apiClient = axios.create({
  baseURL: 'https://upiteru.a-cial-dev.com/api/v1',
  headers: {
    "Content-type": "application/json",
  },
});

apiClient.interceptors.request.use((config:InternalAxiosRequestConfig) => {
  const { token } = useStoreAuth();
  if(token){
    config.headers.Authorization = 'Token ' + token;
  }
  return config;
})

apiClient.interceptors.response.use(
  (response: AxiosResponse) => { return response },
  (error:AxiosError) => {
  console.log(error)
  if(!error.response){
    return error;
  } 
  if ([401, 302].includes(error.response.status)) {
    const { useLogout } = useStoreAuth();
    useLogout();
    router.replace('/login');
  }
  return Promise.reject(error.response.status);
})

export default apiClient;