<template>
  <v-container>
      <lineChart v-if="isLoad" :data="rate_data"/>
      <v-table>
        <thead>
          <tr style="font-size: 0.7rem;">
            <th>日付</th>
            <th>正答率</th>
            <th>時間</th>
            <th>合否</th>
            <th></th>
          </tr>
        </thead>
        <tbody style="font-size: 0.8rem;">
          <tr v-for="(val,key) in resp" :key="key">
            <td>{{ formatDate(val.created_at, 'monthDate') }}</td>
            <td>{{ val.correct_rate * 100 }}%</td>
            <td>{{ Math.floor(val.time_needed / 60) }}:{{ val.time_needed % 60 }}</td>
            <td v-if="val.is_pass == true"> 合 </td>
            <td v-if="val.is_pass == false"> 否 </td>
            <td><v-btn color="secondary" @click.stop="val.index = true" style="font-size: 0.8rem;">詳細</v-btn></td>
            <v-dialog  v-model="val.index">
              <v-sheet>
                <resultDetails :examResult="val" :history="true"/>
                  <v-row justify="space-around" class="pa-2 ma-4">
                    <v-btn @click.stop="val.index = false">閉じる</v-btn>
                </v-row>
              </v-sheet>
            </v-dialog>
          </tr>
        </tbody>
      </v-table>
  </v-container>
</template>
<script setup>
import { ref,onBeforeMount } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';
import { useUtilsFunc } from '@/plugins/utils';
import examAPIs from '@/plugins/apis/practiceExam';
import lineChart from '@/components/modules/students/lineChart.vue';
import { useHistoryRate } from '@/plugins/lib/useHistoryRate';
import resultDetails from '@/components/modules/students/exams/resultDetails.vue';

const { user } = useStoreAuth();
const { formatDate } = useUtilsFunc();
const { rate_data, isLoad, getRateData } = useHistoryRate();

const resp = ref();

onBeforeMount(
  async () => {
    resp.value = await examAPIs.getExamHistory(user.id);
    getRateData(resp.value);
  }
)

</script>