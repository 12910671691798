import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from 'pinia';
import { useStoreAuth } from "@/plugins/pinia";

import LoginForm from '@/components/pages/common/loginForm.vue'
import PasswordResetRequest from '@/components/pages/common/passwordResetRequest.vue'
import PasswordReset from '@/components/pages/common/passwordReset.vue'

import sHome from '@/components/pages/students/sHome.vue';
import sPracticeExam from '@/components/pages/students/sPracticeExam.vue';
import sPracticeMode from '@/components/pages/students/sPracticeMode.vue';
import sMissList from '@/components/pages/students/sMissList.vue';
import sBookmarks from '@/components/pages/students/sBookmarks.vue'
import sExamHistory from '@/components/pages/students/sExamHistory.vue';

import aHome from '@/components/pages/admin/aHome.vue';
import aQuestion from '@/components/pages/admin/aQuestionManage.vue';
import aCourse from '@/components/pages/admin/aCourseManage.vue';
import aStudentDetail from '@/components/pages/admin/aStudentDetail.vue';
import aAdmin from '@/components/pages/admin/aAdminManage.vue'

const routes = [
  { path: '/login', component: LoginForm, meta: { public: true }},

  { path: '/', component: sHome },
  { path: '/exam', component: sPracticeExam },
  { path: '/password/reset/:isAdmin', component: PasswordResetRequest, name:'passwordReset', meta: { public: true }, props: true},
  { path: '/password/reset/:token', component: PasswordReset, meta: { public: true }},
  { path: '/practice', component: sPracticeMode, name: 'Practice', props: true },
  { path: '/missList', component: sMissList, props: true},
  { path: '/bookmarks', component: sBookmarks },
  { path: '/examHistory', component:sExamHistory},

  { path: '/admin/login', component: LoginForm, meta: { public: true}},
  { path: '/admin', component: aHome, meta: { admin: true }},
  { path: '/admin/question', component: aQuestion, meta: { admin: true }},
  { path: '/admin/course', component: aCourse, meta: { admin: true }},
  { path: '/admin/studentDetail/:id/:name', component: aStudentDetail, name:'studentDetail', meta: { admin: true }, props: true},
  { path: '/admin/adminRegister', component: aAdmin, meta: {admin: true }},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to: any, from: any, next: any) => {
  const { isAdmin, isLogin } = storeToRefs(useStoreAuth());

  if (to.matched.some((record: any) => record.meta.admin) && !isAdmin.value) next(from.path);
  
  if (to.matched.some((record: any) => !record.meta.public) && !isLogin.value) {
    isAdmin.value ? next({ path: '/admin/login'}) : next({ path: '/login' });
  } else {
    next();
  }
});

export default router