import { createApp } from 'vue'
import App from './App.vue'

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { vuetifyConf, vuetifyLabsConf } from '@/plugins/vuetify'
import { messagePlugin } from '@/plugins/messagePlugin';
import apiClient from './plugins/axios'
import router from './plugins/router'
import consts from '@/plugins/consts'
import '@mdi/font/css/materialdesignicons.css' 

const app   = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.config.globalProperties.$axios  = apiClient
app.config.globalProperties.$consts = consts

app
.use(messagePlugin)
.use(vuetifyConf)
.use(vuetifyLabsConf)
.use(router)
.use(pinia)
.mount('#app')

app.provide('router', router);
app.provide('consts', consts)