import axios from '@/plugins/axios';
import { ref } from 'vue';

export const useUserList = () => {
  const user_name = ref();
  const selectedCourse = ref();
  const list = ref();
  const getStudentList = async () => {
    const result = await axios.get('/student/lastLogin',{params:{user_name:user_name.value, course:selectedCourse.value}});
    list.value = result.data;
  }
  const getAdminList = async () => {
    const result = await axios.get('/admin/list');
    list.value = result.data;
  }
  return { user_name, selectedCourse, list, getStudentList, getAdminList }
}