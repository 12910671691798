import { ref } from 'vue';
import consts from '@/plugins/consts'

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
interface headers {
  [key: number]: {
    title: string, align?: string, sortable?: boolean, key: string
  }
}


export const useFileReader = () => {
  const inputs = ref(Array());

  const resetInputs = () => inputs.value.splice(0);

  const fileInput = (event:HTMLInputEvent, header:headers) => {
    resetInputs();

    if (event.target.files == null) return;
    const file    = (event.target).files[0];
    const reader  = new FileReader();
    
    const loadFile = () => {
      if (reader.result == null) return;
      const splitter = ((<string>reader.result).indexOf('\r\n') != -1) ? '\r\n' : '\n'
      const lines = (<string>reader.result).split(splitter);
      
      lines.forEach((element:string, key:number) => {
        if (key == 0) return;

        const lineValues = element.split(',');
        if (lineValues.includes('')) return;

        const data = Object();
        lineValues.forEach((ele:string, k:number) => {
          const index = header[k].key

          if (consts.booleTypeHeader.includes(index)) { 
            const value = ele == "TRUE" ? true : false;
            data[index] = value;
          } else {
            data[index] = ele;
          }
        })
        inputs.value.push(data)
      });
    }
    reader.readAsText(file)
    reader.onload = loadFile;
  }

  return { inputs, fileInput, resetInputs }
}