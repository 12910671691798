<template>
  <v-card>
    <v-col v-if="err" cols="12" style="background-color: lightpink;">
      <v-row v-for="(val, key) in err" :key="key">
        <v-text>{{ val }}</v-text>
      </v-row>
    </v-col>
    <v-sheet class="pa-2">
      <v-text-field label="氏名" 
      v-model="name"
      :rules="[required]"
      color="cyan-darken-1"
      prepend-icon="mdi mdi-account"
      clearable/>
      <v-text-field label="メールアドレス" 
      v-model="email"
      :rules="[required,emailRegex]"
      color="cyan-darken-1"
      prepend-icon="mdi mdi-email-outline"
      clearable/>
      <v-text-field label="パスワード" 
      v-model="password"
      :rules="[required,passwordLength]"
      counter="8"
      color="cyan-darken-1"
      prepend-icon="mdi mdi-lock-outline"
      type="password"
      clearable/>
    </v-sheet>
    <v-row justify="space-around" class="pa-2 ma-4">
      <v-btn @click="closeModal">閉じる</v-btn>
      <v-btn color="warning" @click="sendData">登録</v-btn>
    </v-row>
  </v-card>
</template>
<script setup>
import { ref, defineEmits } from 'vue';
import { useValidation } from '@/plugins/validation';
import { useAdminRegister } from '@/plugins/apis/admins/useAdminRegister';

const { required, passwordLength, emailRegex, validation } = useValidation();
const { name, email, password, sendNewAdminData } = useAdminRegister();

let err = ref();

const sendData = () => {
  let data = {
      'name':name.value,
      'email':email.value,
      'password':password.value,
    }
  const validated = validation(data);
  if(validated == true){
    sendNewAdminData();
  }else{
    err.value = validated;
  }
}

const emit       = defineEmits(['toggleModal'])
const closeModal = () => {
  emit('toggleModal');
}
</script>