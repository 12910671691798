<template>
  <teleport to="#flash-message">
    <transition name="fade">
      <div v-if="isShow" class="flash-message" role="alert">
        <div class="message" >{{ messageValue }}</div>
      </div>
    </transition>
  </teleport>
</template>
<script setup>
import { inject } from 'vue';
import { messageKey } from '@/plugins/lib/useMessage';

const provided = inject(messageKey);

if (provided === undefined) {
  throw Error('Message Not Provided');
}
const { messageValue, isShow } = provided;

</script>
<style scoped>
.flash-message {
  position: fixed;
  bottom: 85vh;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  z-index: 99999;
}
.message {
  min-width: 200px;
  min-height: 40px;
  padding: 10px 20px;
  color: #f5f5f5;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  background-color: #7986CB;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>