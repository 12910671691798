<template>
  <div class="ma-2" v-if="resp">
    <v-sheet class="ma-1">
      <v-layout v-if="explanation">
        <v-col>
          <h2 style="text-align:left; font-size: 1.3rem" class="ml-3 mb-2">{{ isCorrect ? '正解' : '不正解' }}</h2>
          <v-divider />  
          <div>{{ resp[progress - 1].explanation }}</div>
          <v-btn v-if="pageFlg" @click="backHome">ホームに戻る</v-btn>
          <v-btn v-else @click="nextQuestion">次の問題へ</v-btn>
        </v-col>
      </v-layout>
      <v-layout v-else>
        <v-col>
          <questionStatement :question="resp[progress]" ref="statements" />
          <v-row justify="space-around" class="mt-3 pa-2">
            <bookmarkButton :question="resp[progress]" />
            <v-btn @click="numberSwitch" :disabled="pageFlg">解説へ</v-btn>
          </v-row>
        </v-col>
      </v-layout>
      <v-row justify="center" class="pa-3">
        {{ explanation ? progress : progress + 1 }}/{{ resp.length }}
      </v-row>
    </v-sheet>
  </div>
  <div v-else>
    <circularLoading />
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { ref, onBeforeMount, toRaw } from 'vue';
import { usePractice } from '@/plugins/apis/usePracticeQuestion';
import { useUtilsFunc } from '@/plugins/utils';
import { useQuestionSwitcher } from '@/plugins/lib/useQuestionSwitcher';
import router from '@/plugins/router';
import circularLoading from '@/components/modules/common/circularLoading.vue';
import questionStatement from '@/components/modules/students/questionStatement.vue';
import bookmarkButton from '@/components/modules/students/practiceQuestion/bookmarkButton.vue';

const { progress, statements, switchQuestion } = useQuestionSwitcher();
const { getPracticeQuestions } = usePractice();
const { equalArray } = useUtilsFunc()
const route = useRoute();

const resp        = ref();
const explanation = ref(false);
const isCorrect   = ref(false);
const pageFlg     = ref(false);

const numberSwitch = () => { 
  const correctAns = toRaw(resp.value[progress.value].corrects)
  const userAnswer = statements.value.getValue()
  isCorrect.value  = equalArray(correctAns, userAnswer);
  switchQuestion();
  pageFlg.value = (resp.value).length == progress.value;
  nextQuestion();
  }
const nextQuestion = () => { 
  explanation.value = !explanation.value;
};

const backHome = () => {
  router.replace('/');
}

onBeforeMount(
  async () => {
    resp.value = await getPracticeQuestions(route.query)
    if(resp.value.length == 0) backHome();
    pageFlg.value = (resp.value).length == progress.value + 1;
  }
);
</script>