import { reactive } from 'vue';

export const useCourseForm = () => {
  const courseForms = reactive({
    'course_name': '', 'limit': '', 'question_count': '', 'pass_line_rate': '', 'pass_line': ''
  })

  const changeCourseData = (type: string) => {
    if(type === 'rate'){
      let count = 0;
      count = Number(courseForms.pass_line_rate)*Number(courseForms.question_count)/100;
      courseForms.pass_line = String(Math.floor(count));
    }else if(type === 'count'){
      let rate = 0;
      rate = Number(courseForms.pass_line)*100/Number(courseForms.question_count);
      courseForms.pass_line_rate = rate.toFixed(2);  
    }
  }  
    
  return {
    courseForms, changeCourseData
  }
}