import { ref } from 'vue';
import axios from '@/plugins/axios'
import { useMessage } from '@/plugins/lib/useMessage'

export const useAdminRegister = () => {
  const { message } = useMessage();

  const name     = ref();
  const email    = ref();
  const password = ref();

  const resetData = () => { 
    name.value       = '';
    email.value      = '';
    password.value   = '';
  }

  const sendNewAdminData = async () => {
    try{
      const resp = await axios.post('admin/register', { 'name':name.value, 'email':email.value, 'password':password.value });
      message('管理者を登録しました');
      return resp.data;  
    } catch (err) {
      if (err == 400) { message('管理者を登録できませんでした') }
    }
  }

  return { name, email, password, resetData, sendNewAdminData }
}