export const useValidation = () => {
  const required = (value: string) => !!value || '必ず入力してください';
  const passwordLength = (value: string) => value.length >= 8 || '8文字以上で入力してください';
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  const emailRegex = (value: string) => regex.test(value) || 'メールアドレスを正しく入力してください';

  const validation = (data:{[key: string]: string}) => {
    const err:Array<string> = [];
    if('name' in data && !data.name) err.push("氏名を入力してください");
    if('email' in data && !data.email) err.push("メールアドレスを入力してください");
    if('email' in data && data.email && !regex.test(data.email)) err.push("メールアドレスを正しく入力してください");
    if('password' in data && !data.password) err.push("パスワードを入力してください");
    if('password' in data && data.password && (data.password).length < 8) err.push("パスワードは8桁以上で入力してください");
    if('passwordConfirm' in data && !data.passwordConfirm) err.push('確認用パスワードを入力してください');
    if('passwordConfirm' in data && data.passwordConfirm && data.password != data.passwordConfirm) err.push('パスワードが一致しません');
    if('start_at' in data && !data.start_at) err.push("開始日を入力してください");
    if('selectedCourse' in data && !data.selectedCourse) err.push("受講コースを選択してください");
    if('course' in data && !data.course) err.push("コースを選択してください");
    if('course_name' in data && !data.course_name) err.push("コース名を入力してください");
    if('question_count' in data && !data.question_count) err.push('出題数を入力してください');
    if('limit' in data && !data.limit) err.push('制限時間を入力してください');
    if('pass_line_rate' in data && !data.pass_line_rate) err.push('合格正答率を入力してください');
    if('pass_line' in data && !data.pass_line) err.push('合格正解数を入力してください');
    if(err.length != 0){
      return err;
    }else{
      return true;
    }
  }

  const chapterValidation = (data:{[key: number]:{[key: string]: string}}) => {
    for (const key in data) {
      const err:Array<string> = [];
      if(!data[key].chapter_name) err.push('章タイトルを入力してください');
      if(!data[key].count) err.push('出題数を入力してください');
      if(!data[key].rate) err.push('割合を入力してください');
      if(err.length != 0){
        return err;
      }else{
        return true;
      }  
    }
  }

  const questionValidation = (data:{[key: number]:{[key: string]: string}}) => {
    for (const key in data) {
      const err:Array<string> = [];
      if(!data[key].chapter) err.push('章番号を入力してください');
      if(!data[key].correct) err.push('解答を入力してください');
      if(!data[key].question) err.push('問題文を入力してください');
      if(!data[key].explanation) err.push('解説文を入力してください');
      if(!data[key].selectsJson[1]){
        err.push('選択肢を入力してください');
      } else if(data[key].selectsJson[1] && !data[key].selectsJson[2]){
        err.push ('選択肢は2つ以上入力してください');
      } 
      if(err.length != 0){
        return err;
      }else{
        return true;
      }
    }
  }

  return{ required, passwordLength, emailRegex, validation, chapterValidation, questionValidation}
}