import { ref } from 'vue';

export const useAnswerGet = () => {
  const choicesR      = ref();
  const getValue      = () => { 
    if (!choicesR.value) { return []; }
    if (!choicesR.value.length) { return [choicesR.value]; }

    const answers:Array<Number> = [];
    choicesR.value.forEach((element: Number) => {
      answers.push(element)
    });
    return answers.sort();
  };
  
  const setModelVal = (answers:Array<Number>) => {
    if(!answers) return;
    answers.forEach(element => {
      choicesR.value.push(element);
    });
  }

  const resetValues = (answers:Array<Number>) => {
    choicesR.value = [];
    setModelVal(answers);
  }

  return {
    choicesR, getValue, resetValues, setModelVal
  }
}