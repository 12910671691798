<template>
<v-container>
    <v-row justify="center">
        <v-col cols="12">
            <v-col v-if="err" style="background-color: lightpink;">
                <v-row v-for="(val, key) in err" :key="key">
                    <v-text>{{ val }}</v-text>
                </v-row>
            </v-col>
            <v-sheet>
                <v-subheader>
                    <h3 class="mt-8 mb-6">パスワードリセット</h3>
                </v-subheader>
                <v-text-field 
                prepend-icon="mdi mdi-lock-outline"
                v-model="password"
                label="新しいパスワード"
                :rules="[required,passwordLength]"
                counter="8"
                type="password"
                color="cyan-darken-1"
                clearable
                />
                <v-text-field 
                v-model="passwordConfirm"
                label="確認用"
                :rules="[required,passwordLength,matchCheck]"
                counter="8"
                prepend-icon="mdi mdi-lock-check-outline"
                type="password"
                color="cyan-darken-1"
                clearable
                />
                <v-btn color="cyan-darken-1" @click="passwordResets" class="mb-8 mt-8">パスワードを変更する</v-btn>
            </v-sheet>
        </v-col>
    </v-row>
</v-container>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthorization } from '@/plugins/apis/useAuthorization'
import { useRoute } from 'vue-router';
import { useValidation } from '@/plugins/validation';

const { password, passwordConfirm, passwordReset } = useAuthorization();
const { required, passwordLength, validation } = useValidation();

const token = useRoute().params.token;

let err = ref();
const passwordResets = async () => {
    let data = {
    'password':password.value,
    'passwordConfirm':passwordConfirm.value,
    }
    const validated = validation(data);
    if(validated == true){
        await passwordReset(token);
    }else{
        err.value = validated;
    }
}

const matchCheck = (value) => value === password.value || 'パスワードが一致していません';

</script>