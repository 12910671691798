<template>
  <v-row v-if="questionDataR">
    <v-col cols="12">
      <v-sheet class="ma-1">
        <div class="questionText">
          <v-divider />
          <p style="font-size: 0.95rem;">{{ questionDataR.question }}</p>
          <v-divider />
          <img v-if="questionDataR.image" :src="questionDataR.image_url" alt="問題画像" style="width: 15rem; height: auto; margin-top: 1rem;">
        </div>
        <v-row justify="space-around" class="mt-1 mb-1">
            <v-col v-for="(val, key) in questionDataR.selects" :key="key" cols="10" md="6" class="pa-1">
              <v-card class="pa-2">
                <p style="font-size: 0.9rem;">{{ key + 1 }} : {{ questionDataR.selects_json[val] }}</p>
              </v-card>
            </v-col>
            <v-btn-toggle
              v-if="!props.stopped"
              v-model="choicesR"
              color="orange"
              variant="outlined"
              :multiple="switchMulti(props.question.selects_num)"
              divided
              class="mt-3"
            >
              <v-btn v-for="(val, key) in questionDataR.selects" :key="key" :value="val">{{ key + 1 }}</v-btn>
            </v-btn-toggle>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script setup>
import { defineProps, defineExpose, watch } from 'vue';
import { useAnswerGet } from '@/plugins/lib/useAnswersGetter';
import { useQuestionData } from '@/plugins/lib/useQuestionDatas';

const props = defineProps({
  question: { type: Object },
  answerLists: {
    type: Array,
    default: undefined,
  },
  stopped: {type: Boolean}
});

const { choicesR, getValue, resetValues, setModelVal } = useAnswerGet();
const { questionData, switchMulti } = useQuestionData();

const questionDataR = questionData(props.question);

watch(() => props.question, (newValue) => {
  questionDataR.value = newValue;
  resetValues();
  setModelVal(props.answerLists);
});

defineExpose({ getValue });
</script>