import { ref } from 'vue';


export const useUtilsFunc = () => {
  const getAnswersAndCast = (values:Array<Number> | undefined) => {
    if (!values) { return []; }
    if(!values.length) { return [values]; }

    const answers:Array<Number> = [];
    values.forEach(element => {
      answers.push(element)
    });
    return answers.sort();
  }

  const equalArray = (arrayA: Array<any>, arrayB: Array<any>) => {
    if (arrayA.length != arrayB.length) return false;
    for (let i = 0, n = arrayA.length; i < n; ++i) {
      if (arrayA[i] !== arrayB[i]) return false;
    }
    return true;
  } 

  const formatDate = (dateValue: any, type: string) =>{
    if(!dateValue) {return ''; }
    if (type == 'all'){
      const date = dateValue.substr(0,10);
      return date;
    } else if(type == 'monthDate'){
      const date = dateValue.substr(5,2)+'/'+dateValue.substr(8,2);
      return date;
    }
  }



  return { getAnswersAndCast, equalArray, formatDate }
}