import { ref, reactive} from 'vue';
import consts from '@/plugins/consts'

interface selects {
  [key: number]: {
    title: string, align: string, key: string
  }
}

interface questions {
  [key: string]: object
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export const useGetQuestionfFileColumns = () => {
  const selectsObj:selects = reactive({})
  const headers            = ref(Array());

  const getColumns = (event:HTMLInputEvent) => {
    headers.value = [];

    if (event.target.files == null) return;
    const file    = (event.target).files[0];
    const reader  = new FileReader();

    const loadFile = () => {
      if (reader.result == null) return;
      const splitter = ((<string>reader.result).indexOf('\r\n') != -1) ? '\r\n' : '\n'
      const lines = (<string>reader.result).split(splitter);
      const headerTitle = lines[0].split(',');

      consts.fixedHeader.forEach((element, key) => {
        const header = { title: headerTitle[key], align: 'left', key: element }
        headers.value.push(header)
      });

      const selects = headerTitle.slice(consts.fixedHeader.length)
      selects.forEach((element, key) => {
        const header = { title: '選択肢'+ (key+1), align: 'left', key: 'select_' + (key+1) }
        selectsObj[key] = header;
        headers.value.push(header)
      })
    }
    reader.readAsText(file)
    reader.onload = loadFile;
  }

  const selectToObj = (inputs:Array<questions>) => {

    inputs.forEach((ele:questions, key:number) => {
      
      if (Object.prototype.hasOwnProperty.call(ele, 'selectsJson')) return;

      const selects:{[key:string]: object} = {};
      Object.keys(ele).forEach((e:string, k:number) => {
        if (consts.fixedHeader.includes(e)) return;
        const number = e.split('_')[1]
        selects[number] = ele[e];
      })
      ele['selectsJson'] = selects;
    })
    return inputs;
  }

  const deleteRowSelects = (datas:Array<questions>) => {
    const parameters:{[key:number]: questions} = {};

    datas.forEach((ele:questions, key:number) => {
      const eachParams:questions = {}
      
      Object.keys(ele).forEach((e:string, k:number) => {
        if (e.indexOf('select_') > -1) return;
        eachParams[e] = ele[e]
      })
      parameters[key] = eachParams;
    })
    return datas;
  }

  return {
    headers, selectsObj, getColumns, selectToObj, deleteRowSelects
  }
}