import axios from '@/plugins/axios';
import { useMessage } from '@/plugins/lib/useMessage'
import router from '@/plugins/router'

export const useChangeActive = () => {
  const { message } = useMessage();

  const notActive = async (user_id:number, type: boolean) => {
    try{
      await axios.put('/user/notActive/'+user_id);
      message('退職済に変更しました')
      if(type){
        router.push('/admin');
      }else{
        location.reload();
      }
    } catch (err){
      if (err == 400) { message('退職済に変更できませんでした') }
    }
  }
  return{
    notActive
  }
}