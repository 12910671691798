<template>
  <Radar :data="props.data" :options="consts.options" />
</template>
<script setup>
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
import { Radar } from 'vue-chartjs'
import { defineProps, inject } from 'vue';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
const consts = inject('consts')
const props = defineProps(['data']);

</script>
<style>
canvas {
  display: inline-block !important; 
}
</style>