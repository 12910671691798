<template>
  <v-container>
    <v-laypout>
      <v-sheet>
        <v-row justify="center">
          <v-col cols="12">
            <v-text style="font-size:1.5rem;">{{ course.course_name }}</v-text>
          </v-col>
          <v-col cols="12">
            <v-text style="font-size:1.2rem;">制限時間: {{ course.limit / 60 }} 分</v-text>
          </v-col>
          <v-col cols="12">
            <v-text style="font-size:1.2rem;">出題数: {{ course.question_count }} 問</v-text>
          </v-col>
        </v-row>
      </v-sheet>
      <v-btn class="mt-4" color="warning" @click="examStart">模試開始</v-btn>
    </v-laypout>
  </v-container>
</template>
<script setup>
import { defineEmits } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';

const { course } = useStoreAuth();

const emit      = defineEmits(['examStart']);
const examStart = () => { emit('examStart') };
</script>