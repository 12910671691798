<template>
  <v-container>
    <v-text style="font-size:1.3rem;" >間違えたリスト</v-text>
    <selectMissChapter v-if="result" :list="result"/>
  </v-container>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';
import { useBookmarks } from '@/plugins/apis/useBookmarks';
import selectMissChapter from '@/components/modules/students/missedQuestion/selectMissChapter.vue';

const { user } = useStoreAuth();
const { getMissList } = useBookmarks();

const result = ref();
onBeforeMount(
  async () => {
    result.value = await getMissList(user.id)
  }
)
</script>