import { ref, reactive } from 'vue';
import consts from '@/plugins/consts'
import { examHistoryResponse, lineGraphData } from './Interface/examHistories';
import { useUtilsFunc } from '@/plugins/utils';

export const useHistoryRate = () => {
  const { formatDate } = useUtilsFunc();
  const rate_data:lineGraphData = reactive({'labels': consts.lineGraphData.labels, 'datasets': consts.lineGraphData.datasets })
  const isLoad = ref(false);

  const getRateData = async (resp:examHistoryResponse) => {
    const length = Object.keys(resp).length;
    rate_data.labels = []
    rate_data.datasets[0].data = []
    if(length != 0){
      for(let key=length-1; key>=0; key--) {
        const date = formatDate(resp[key].created_at,'monthDate')
        rate_data.labels.push(date);
        rate_data.datasets[0].data.push(Number(resp[key].correct_rate)*100)
      }  
    }
    isLoad.value = true;
  }
  return{ rate_data, isLoad, getRateData }
}