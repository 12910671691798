import axios from '@/plugins/axios';
import { ref } from 'vue';

export const useChapterData = () => {
  const course_id = ref();
  const list = ref();
  const chapterList = async () => {
    const result = await axios.get(course_id.value+'/chapters');
    list.value = result.data;
  }
  return { course_id, list, chapterList }
}