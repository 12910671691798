<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-col v-if="err" style="background-color: lightpink;">
                    <v-row v-for="(val, key) in err" :key="key">
                        <v-text>{{ val }}</v-text>
                    </v-row>
                </v-col>
                <v-sheet>
                    <v-subheader>
                        <h3 class="mt-8 mb-6">パスワード再設定</h3>
                    </v-subheader>
                    <v-text-field label="メールアドレス"
                        v-model="email"
                        :rules="[required, emailRegex]"
                        prepend-icon="mdi mdi-email-arrow-right-outline"
                        color="cyan-darken-1"
                        clearable
                        />
                    <v-btn color="cyan-darken-1" @click="passwordResetRequests" class="mb-8 mt-8">パスワード再設定用のリンクを送信</v-btn>
                </v-sheet>
                <router-link v-if="props.isAdmin == 'true'" to="/admin/login">ログイン画面へ</router-link>
                <router-link v-if="props.isAdmin == 'false'" to="/login">ログイン画面へ</router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import { useAuthorization } from '@/plugins/apis/useAuthorization'
import { useValidation } from '@/plugins/validation';

const { email, passwordResetRequest } = useAuthorization();
const { required, emailRegex, validation } = useValidation();

const props  = defineProps(['isAdmin']);

let err = ref();
const passwordResetRequests = async () => {
    let data = {
        'email': email.value
    };
    const validated = validation(data);
    if(validated == true){
        await passwordResetRequest();
    }else{
        err.value = validated;
    }
}

</script>
