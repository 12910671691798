<template>
  <v-card>
    <v-sheet class="pa-2">
      <text style="font-size:1.3rem; text-align: left;">コース追加</text>
      <v-col v-if="err" cols="12" style="background-color: lightpink;">
        <v-row v-for="(val, key) in err" :key="key">
          <v-text>{{ val }}</v-text>
        </v-row>
      </v-col>
      <v-text-field label="コース名" 
        v-model="courseForms.course_name" 
        :rules="[required]"
        color="cyan-darken-1"
        clearable/>
      <v-text-field label="出題数" 
        v-model="courseForms.question_count" 
        :rules="[required]"
        color="cyan-darken-1"
        clearable/>
      <v-text-field label="制限時間(分)" 
        v-model="courseForms.limit" 
        :rules="[required]"
        color="cyan-darken-1"
        clearable/>
      <v-text-field label="合格正答率" 
        v-model="courseForms.pass_line_rate"
        :rules="[required]" 
        @change="changeCourseData(type='rate')"
        color="cyan-darken-1"
        clearable/>
      <v-text-field label="合格正解数" 
        v-model="courseForms.pass_line" 
        :rules="[required]"
        @change="changeCourseData(type='count')"
        color="cyan-darken-1"
        clearable/>
    </v-sheet>
    <v-sheet class="pa-2">
      <text style="font-size:1.3rem; text-align: left;">章追加</text>
      <v-row v-for="(val, key) in chapterForms" :key="key" v-row justify="space-around">
        <v-col cols="3">
          <v-text-field label="章タイトル" 
          v-model="chapterForms[key]['chapter_name']"
          :rules="[required]"
          color="cyan-darken-1"
          clearable/>
        </v-col>
        <v-col cols="3">
          <v-text-field label="割合" v-model="chapterForms[key]['rate']" 
          @change="changeChapterData(type='rate',courseForms.question_count)"
          :rules="[required]"
          color="cyan-darken-1"
          clearable/>
        </v-col>
        <v-col cols="3">
          <v-text-field label="出題数" 
          v-model="chapterForms[key]['count']" 
          @change="changeChapterData(type='count',courseForms.question_count)"
          :rules="[required]"
          color="cyan-darken-1"
          clearable/>
        </v-col>
      </v-row>
      <v-btn @click="choicesPlus" class="mx-2"><v-icon>mdi-plus</v-icon></v-btn>
      <v-btn @click="choicesMinus" class="mx-2"><v-icon>mdi-minus</v-icon></v-btn>
    </v-sheet>
    <v-row justify="space-around" class="pa-2 ma-4">
      <v-btn @click="closeModal">閉じる</v-btn>
      <v-btn color="warning" @click="sendData">登録</v-btn>
    </v-row>
  </v-card>
</template>
<script setup>
import { ref, defineEmits } from 'vue';
import { useValidation } from '@/plugins/validation';
import { useFileReader } from '@/plugins/lib/admins/useFileReader';
import { useCourseRegister } from '@/plugins/apis/admins/useCourseRegister';
import { useCourseForm } from '@/plugins/lib/admins/useCourseForm';
import { useChapterRegister } from '@/plugins/apis/admins/useChapterRegister';
import { useChapterForm } from '@/plugins/lib/admins/useChapterForm';
import { useMessage } from '@/plugins/lib/useMessage'

const { required, validation, chapterValidation } = useValidation();
const { resetInputs } = useFileReader();
const { sendCourse, course_id } = useCourseRegister();
const { courseForms, changeCourseData } = useCourseForm();
const { sendChapter } = useChapterRegister();
const { chapterForms, choicesPlus, choicesMinus, changeChapterData } = useChapterForm();
const { message } = useMessage();

const emit = defineEmits(['toggleModal']);
const closeModal = () => {
  resetInputs();
  emit('toggleModal');
}

let err = ref();

const sendData = async () => {
  err.value = [];
  // 問題数チェック
  let list = [];
  let all_count = 0;
  Object.keys(chapterForms).forEach(function(index){
    list.push(chapterForms[index]);
    all_count += Number(chapterForms[index].count);
  });
  // バリデーション
  const result = validation(courseForms);
  const validated = chapterValidation(chapterForms);
  if(result != true){
    Object.keys(result).forEach(function(index){
    err.value.push(result[index]);
    });
  }
  if(validated != true){
    Object.keys(validated).forEach(function(index){
    err.value.push(validated[index]);
    });
  }
  if(Number(courseForms.question_count) != all_count){
    err.value.push('コースの問題数と章の問題数の合計が一致しません');
  }

  if(Number(courseForms.question_count) === all_count && result == true && validated == true){
    try{
      await sendCourse(courseForms);
      let data = {
        'course_id':course_id.value,
        'chapters':list
      }
      await sendChapter(course_id.value,data);
      message('章とコースを登録しました')
      err.value = null
    }catch(err){
      if (err == 400) { message('章とコースを登録できませんでした') }
    }
  }
}

</script>