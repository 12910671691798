<template>
  <circularLoading v-if="result == undefined" />
  <reusltDetails v-else-if="!showSummary" :examResult="result" />
  <v-layout v-else-if="result && showSummary">
    <v-col cols="12">
      <v-sheet class="ma-1">
        <v-sheet>
          <h2 style="text-align:left; font-size: 1.3rem" class="ml-3 mb-2">{{ result.course.course_name }} 模試結果</h2>
          <v-divider />
          <div class="pa-3">
            <h3 :style="result.is_pass ? 'color: red;' : 'color: blue;'">
              {{ result.is_pass ? '合格' : '不合格'}}
            </h3>
            <p>全{{ result.course.question_count }}問中  <b style="font-size:1.2rem;">{{ result.totalCorrects }}</b>  問正解でした</p>  
            <p>(正答率: {{ (result.totalCorrects / result.course.question_count).toFixed(2)*100 }}%)</p>
          </div>
        </v-sheet>
        <v-sheet class="ma-2">
          <h4 style="text-align:left;">章別正答率</h4>
          <v-divider />
          <div class="pa-3">
            <v-col>
              <v-row justify="space-around" v-for="(val, key) in result.course.chapterCourse" :key="key">
                <span>{{ val.order }}章:{{ result.correctCount[val.id].counts }}/{{ val.count }}問正解</span>
                <span>({{ (result.correctCount[val.id].counts / val.count).toFixed(2) * 100 }}%)</span>
              </v-row>
            </v-col>
          </div>
        </v-sheet>
      </v-sheet>
      <v-row justify="space-around" class="pa-3">
        <v-btn color="warning" @click="goExplanation">問題の解説へ</v-btn>
        <v-btn color="primary" @click="backHome">ホームに戻る</v-btn>
      </v-row>
    </v-col>
  </v-layout>
</template>
<script setup>
import router from '@/plugins/router'
import { ref, onBeforeMount, defineProps } from 'vue';
import examAPIs from '@/plugins/apis/practiceExam';
import reusltDetails from '@/components/modules/students/exams/resultDetails.vue';
import circularLoading from '@/components/modules/common/circularLoading.vue';

const props  = defineProps(['id']);
let result = ref();
let showSummary = ref(true);

onBeforeMount( async () => {
  result.value = await examAPIs.retrieveExamResult(props.id);
})
const goExplanation = () => showSummary.value = false;

const backHome = () => router.replace('/');
</script>
<style>
.correct {
  border: solid 1px orange;
}

.selected {
  color: red;
}
</style>