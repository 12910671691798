import { ref } from 'vue';
import axios from '@/plugins/axios'
import { useStoreAuth } from '@/plugins/pinia'

export const usePractice = () => {
  const practiceQuestion = ref();

  const getPracticeQuestions = async (params:object) => {
    const { user, course } = useStoreAuth();
    const parameters = { 'user': user.id, 'course': course.id }
    const result = await axios.post('/practice', { ...parameters }, { params: params });

    return result.data;
  }
  return { practiceQuestion, getPracticeQuestions }
}