<template>
  <v-container>
    <v-sheet class="ma-2">
      <v-row justify="space-between">
        <v-col cols="10" align="left">
          <v-text style="font-size:1.3rem; text-align: left;">問題管理</v-text>
        </v-col>
        <v-col cols="2">
          <v-btn color="secondary" @click="toggleModal">問題追加</v-btn>
        </v-col>
      </v-row>
      <v-sheet>
        <v-select 
        class="pa-3"
        :items="courses"
        item-title="course_name" item-value="id"
        v-model="selectedCourse"
        label="コース選択" 
        @update:modelValue="changeCourseId()"/>
        <v-row v-if="list">
          <v-col v-for="(val, key) in list" :key="key">
            <v-btn @click="selectChapter(val.id)">{{ val.chapter_name }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <questionsList v-if="questionData" :list="questionData" :course="selectedCourse" @toggleModal="toggleModal"/>
        </v-row>
      </v-sheet>
    </v-sheet>
    <v-dialog v-model="isShow" persistent>
      <questionRegister @toggleModal="toggleModal" :course="selectedCourse"/>
    </v-dialog>
  </v-container>
</template>
<script setup>
import { onBeforeMount } from 'vue';
import { useToggleModal } from '@/plugins/lib/useToggleModal'
import { useGetCourse } from '@/plugins/apis/useGetCourses';
import { useChapterData } from '@/plugins/apis/useChapterData';
import { useQuestionData } from '@/plugins/apis/useQuestionDataList';

import questionRegister from '@/components/modules/admin/questionRegister.vue';
import questionsList from '@/components/modules/admin/questionsList.vue';

onBeforeMount( async () => { await getCourses(); })

const { courses, selectedCourse, getCourses } = useGetCourse();
const { isShow, toggleModal } = useToggleModal();
const { course_id, list, chapterList } = useChapterData();
const { questionData,questionList } = useQuestionData();

const changeCourseId = async () => {
  questionData.value = null
  course_id.value = selectedCourse.value;
  await chapterList();
}

const selectChapter = async (chapter_id) =>{
  await questionList(chapter_id);
}

</script>