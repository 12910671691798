<template>
  <v-container>
    <v-row justify="center">
      <v-col v-if="err" cols="12" style="background-color: lightpink;">
        <v-row v-for="(val, key) in err" :key="key">
          <v-text>{{ val }}</v-text>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-sheet>
          <v-text-field label="メールアドレス"
            v-model="email" 
            :rules="[required,emailRegex]"
            color="cyan-darken-1"
            prepend-icon="mdi mdi-email-outline" 
            clearable/>
          <v-text-field label="パスワード"
          v-model="password" 
          :rules="[required,passwordLength]" 
          counter="8"
          color="cyan-darken-1"
          prepend-icon="mdi mdi-lock-outline"
          type="password"
          clearable/>
          <v-btn @click="sendData">Login</v-btn>
          <p class="mt-2"><u><router-link :to="{ name:'passwordReset', params: { isAdmin: isAdmin }}">パスワードを忘れた方</router-link></u></p>
          <router-link v-if="isAdmin && adminLogin" @click.stop="adminLogin = !adminLogin" to="/login">生徒ログイン画面へ</router-link>
          <router-link v-else-if="isAdmin && !adminLogin" @click.stop="adminLogin = !adminLogin" to="/admin/login">管理者ログイン画面へ</router-link>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { useAuthorization } from '@/plugins/apis/useAuthorization'
import { useValidation } from '@/plugins/validation';

const { email, password, isAdmin, login } = useAuthorization();
const { required, passwordLength, emailRegex, validation} = useValidation();

const adminLogin = ref(true);

let err = ref();
const sendData = () => {
  let data = {
      'email':email.value,
      'password':password.value,
    }
  const result = validation(data);
  if(result == true){
    login();
  }else{
    err.value = result;
  }
}
</script>