<template>
  <v-layout>
    <v-col cols="12">
      <v-sheet class="ma-1">
        <v-sheet>
          <h2 style="text-align:left; font-size: 1.3rem" class="ml-3 mb-2">{{ props.examResult.course.course_name }} 模試解説</h2>
          <v-text style="font-size: 0.8rem">
            正答は<span style="color: orange;">オレンジ</span>, 回答は<span style="color: red;">赤</span>で表示
          </v-text>
          <v-divider />
        </v-sheet>
        <v-row v-for="(val, key) in props.examResult.detailHistory" :key="key" justify="space-around" class="mt-1 mb-1">
          <v-col cols="12">
            <v-text style="font-size:1.2rem;">{{ key + 1 }}問目</v-text>
            <v-divider />
            <v-text>{{ val.question.question }}</v-text>
            <v-divider />
            <v-row class="pa-3">
              <v-col v-for="(v, k) in val.order" :key="k" cols="12">
                <v-sheet color="grey-lighten-3" rounded="rounded" :class="{'correct': isInArray(v, val.question.corrects)}">
                  <v-text class="pa-2" :class="{'selected': isInArray(v, val.choices)}">
                    {{ val.question.selects_json[v] }}
                  </v-text>
                </v-sheet>
              </v-col>
            </v-row>
            <v-expansion-panels variant="accordion">
              <v-expansion-panel title="解説" :text="val.question.explanation" elevation="0" />
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-btn v-if="!props.history" color="success" @click="backHome">ホームに戻る</v-btn>
        </v-row>
      </v-sheet>
    </v-col>
  </v-layout>
</template>
<script setup>
import { defineProps } from 'vue';
import router from '@/plugins/router'

const props     = defineProps(['examResult','history']);
const isInArray = (key, array) => { return array.includes(key) }
const backHome  = () => router.replace('/');
</script>