<template>
  <v-container>
    <v-sheet class="ma-2">
      <v-row justify="space-between">
        <v-col cols="10" align="left">
          <v-text style="font-size:1.3rem; text-align: left;">コース管理</v-text>
        </v-col>
        <v-col cols="2">
          <v-btn color="secondary" @click="toggleModal">コース追加</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" align="left">
          <v-text style="font-size:1.3rem; text-align: left;">章一覧</v-text>
        </v-col>
      </v-row>
      <v-select 
      class="pa-3"
      v-model="course_id"
      :items="courses"
      @update:modelValue="changeCourseId()"
      item-title="course_name" 
      item-value="id"
      label="コース選択" 
      />
      <v-table>
        <thead>
          <tr>
            <th>章No.</th>
            <th>章タイトル</th>
            <th>出題数</th>
            <!-- <th></th> -->
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(val, key) in list" :key="key">
            <td>{{ val.order }}</td>
            <td>{{ val.chapter_name }}</td>
            <td>{{ val.count }}</td>
            <!-- <td><v-btn>編集</v-btn></td> -->
          </tr>
        </tbody>
      </v-table>
    </v-sheet>
    <v-dialog v-model="isShow" persistent>
      <courseRegister 
      @toggleModal="toggleModal" />
    </v-dialog>
  </v-container>
</template>
<script setup>
import { onBeforeMount } from 'vue';
import { useToggleModal } from '@/plugins/lib/useToggleModal'
import { useGetCourse } from '@/plugins/apis/useGetCourses';
import { useChapterData } from '@/plugins/apis/useChapterData';
import courseRegister from '@/components/modules/admin/courseRegister.vue';

const { courses, getCourses } = useGetCourse();
const { isShow, toggleModal } = useToggleModal();
const { course_id, list, chapterList } = useChapterData();

onBeforeMount( async () => { 
  await getCourses();
})

const changeCourseId = async () => {
  await chapterList();
}


</script>