<template>
  <v-container>
    <v-sheet class="ma-2">
      <v-row justify="space-between">
        <v-col cols="10" align="left">
          <v-text style="font-size:1.3rem; text-align: left;">生徒管理</v-text>
        </v-col>
        <v-col cols="2">
          <v-btn color="secondary" @click="toggleModal">生徒追加</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog v-model="isShow" persistent>
      <studentRegister @toggleModal="toggleModal" />
    </v-dialog>
    <v-row>
      <v-col cols="10" align="left">
        <v-text style="font-size:1.3rem; text-align: left;">生徒一覧</v-text>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="4">
        <v-select 
          :items="courses"
          item-title="course_name" 
          item-value="id"
          v-model="selectedCourse"
          label="コース選択" />
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="user_name" label="生徒氏名" />
      </v-col>
      <v-col cols="2">
        <v-btn color="success" @click="getList()">絞り込む</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn color="warning" @click="reset()">リセット</v-btn>
      </v-col>
    </v-row>
      <v-table>
        <thead>
          <tr>
            <th>名前</th>
            <th>最終ログイン</th>
            <!-- <th>練習履歴(最新)</th>
            <th>模試履歴(最新)</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, key) in list" :key="key">
            <td>{{ val.name }}</td>
            <td>{{ (formatDate(val.last_login,'all')) }}</td>
            <!-- <td></td>
            <td></td> -->
            <td><router-link :to="{ name:'studentDetail', params: { id: val.id, name: val.name }}"><v-btn>詳細</v-btn></router-link></td>
          </tr>
        </tbody>
      </v-table>
  </v-container>
</template>
<script setup>
import { onBeforeMount } from 'vue';
import { useUtilsFunc } from '@/plugins/utils';
import { useToggleModal } from '@/plugins/lib/useToggleModal';
import studentRegister from '@/components/modules/admin/studentRegister.vue';
import { useUserList } from '@/plugins/apis/admins/useUserList';
import { useGetCourse } from '@/plugins/apis/useGetCourses';

const { isShow, toggleModal } = useToggleModal();

const { courses, getCourses }   = useGetCourse();

const {user_name, selectedCourse, list, getStudentList} = useUserList();

const { formatDate } = useUtilsFunc();

onBeforeMount( async () => { 
  await getCourses();
  await getStudentList();
  formatDate();
})

const getList = async () => { 
  await getStudentList();}

const reset = async () => {
  user_name.value = undefined;
  selectedCourse.value = undefined;
  await getStudentList();
}

</script>