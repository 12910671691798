<template>
  <v-app-bar id="appBar">
    <template v-slot:prepend v-if="isLogin">
      <v-app-bar-nav-icon @click.stop="isShows = !isShows" />
    </template>

    <v-toolbar-title>Upiteru</v-toolbar-title>

    <template v-slot:append v-if="isLogin">
      <v-icon>mdi-account</v-icon>
      <v-menu activator="parent">
        <v-list>
          <v-list-item>
            <v-list-item-title @click="toggleModal">パスワード変更</v-list-item-title>
            <v-list-item-title class="mt-4" @click="logout">ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
  <v-dialog v-model="isShow" persistent>
      <passwordUpdate 
      @toggleModal="toggleModal" />
  </v-dialog>
  <v-navigation-drawer
    v-model="isShows"
    absolute
    bottom
    temporary
  >
    <v-list nav dense v-if="!isAdmin | isStudent">
      <v-list-item-group
      v-model="menus"
      active-class="cyan--text text--accent-4"
      >
        <v-list-item to="/" link title="Home" />
        <v-list-item to="/examHistory" link title="模試履歴" />
        <v-list-item to="/bookmarks" link title="ブックマーク問題" />
      </v-list-item-group>
    </v-list>
    <v-list nav dense v-else-if="isAdmin && !isStudent">
      <v-list-item-group
        v-model="menus"
        active-class="cyan--text text--accent-4"
      >
        <router-link to="/admin"><v-list-item link title="生徒管理" /></router-link>
        <router-link to="/admin/adminRegister"><v-list-item link title="管理者管理"/></router-link>
        <router-link to="/admin/course"><v-list-item link title="コース管理" /></router-link>
        <router-link to="/admin/question"><v-list-item link title="問題管理" /></router-link>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia'
import { useStoreAuth } from '@/plugins/pinia';
import { useToggleModal } from '@/plugins/lib/useToggleModal';
import router from '@/plugins/router';
import passwordUpdate from './passwordUpdate.vue';

const { isLogin, isAdmin, isStudent } = storeToRefs(useStoreAuth());
const { useLogout } = useStoreAuth();
const { isShow, toggleModal } = useToggleModal();

const logout = () => {
  router.replace(isAdmin.value ? '/admin/login' : 'login');
  useLogout();
}

let isShows = ref(false);
let menus  = ref(0);
</script>
<style>
#appBar {
  text-align: left;
}
</style>