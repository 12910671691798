import axios from '@/plugins/axios'
import { ref } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';

export const useGetCourse = () => {
  const { selectCourse } = useStoreAuth();
  const courses = ref();
  const course_id = ref();
  const selectedCourse = ref(); 
  
  const getCourses = async () => {
    const resp    = await axios.get('courses');
    courses.value = resp.data;
  }

  const courseInfo = async () => {
    const result = await axios.get('courses/'+course_id.value)
    selectCourse(result.data);
  }

  return {
    courses, course_id, selectedCourse, getCourses, courseInfo
  }
}