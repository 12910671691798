<template>
  <v-container>
    <v-text style="font-size:1.3rem;" >ブックマーク問題</v-text>
    <selectMissChapter v-if="resp" :list="resp" />
  </v-container>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';
import { useBookmarks } from '@/plugins/apis/useBookmarks'
import selectMissChapter from '@/components/modules/students/missedQuestion/selectMissChapter.vue';

const { user } = useStoreAuth();
const { getBookmarks } = useBookmarks();

const resp = ref();
onBeforeMount(
  async () => {
    resp.value = await getBookmarks(user.id);
  }
)
</script>