import { defineStore } from 'pinia';
import { loginResponse } from './lib/Interface/loginResponse';
import { ref } from 'vue';

export const useStoreAuth = defineStore('auth', () => {
  const token      = ref();
  const user       = ref();
  const course     = ref();
  const isLogin    = ref(false);
  const isAdmin    = ref(false);
  const isStudent  = ref(false);

  const saveToken  = (resp:string) => {
    token.value   = resp;
    isLogin.value = true;
  };
  const saveUserInfos = (userResp:object) => {
    user.value = userResp;
  }
  const savecourse = (courseRes:Array<object>) => {
    course.value     = courseRes;
  }
  const saveIsADmin = (admin:boolean) => {
    isAdmin.value = admin;
  }
  const useLogin = (resp:loginResponse) => {
    saveToken(resp.token.token);
    saveUserInfos(resp.user);
    saveIsADmin(resp.user.is_admin);
    if (resp.user.is_admin) return;

    savecourse(resp.user.course);
  }
  const selectCourse = (selectCourse:object) => {
    course.value = selectCourse;
  }
  const adminStudent = (type:boolean) => {
    isStudent.value = type;
  }
  const useLogout = () => {
    token.value      = '';
    user.value       = {};
    course.value     = {};
    isLogin.value    = false;
    isAdmin.value    = false;
    isStudent.value  = false;
  }


  return { 
    useLogin, useLogout, selectCourse, adminStudent, token, user, course, isLogin, isAdmin, isStudent
   }
},
  { 
    persist: true
  }
);