<template>
  <span @click="bookmark">
    <v-sheet v-if="question.is_bookmarked">
      <v-icon>mdi-bookmark-check</v-icon>Bookmarked
    </v-sheet>
    <v-sheet v-else>
      <v-icon>mdi-bookmark-outline</v-icon>Bookmark
    </v-sheet>
</span>
</template>
<script setup>
import { toRefs, defineProps } from 'vue';
import { useStoreAuth } from '@/plugins/pinia';
import { useBookmarks } from '@/plugins/apis/useBookmarks'

const props = defineProps(['question'])

const { question } = toRefs(props)
const { bookmarkParam, sendBookmark } = useBookmarks()

const { user } = useStoreAuth()

const bookmark = async () => {
  bookmarkParam.user_id     = user.id;
  bookmarkParam.question_id = question.value.id;
  const resp = await sendBookmark();
  question.value.is_bookmarked = resp.bookmark;
}
</script>