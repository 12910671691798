import axios from '@/plugins/axios';
import { ref } from 'vue';

export const useQuestionData = () => {
  const questionData = ref();
  const questionList = async (chapter_id: Number) => {
    const result = await axios.get(chapter_id+'/questions');
    questionData.value = result.data;
  }
  return { questionData, questionList }
}