import axios from '@/plugins/axios';

const examAPIs = {

  getExam: async (course_id:number) => {
    const result = await axios.get('exam/' + course_id);
    return result.data; 
  },
  sendExam: async (course_id:number, params:Object) => {
    const result = await axios.post('exam/' + course_id, params);
    return result.data;
  },
  retrieveExamResult: async (id:number) => {
    const result = await axios.get('exam/result/' + id);
    return result.data;
  },
  getExamHistory: async (user_id:number) => {
    const result = await axios.get('/exam/history/' + user_id);
    return result.data;
  },
}

export default examAPIs