import { ref, reactive } from 'vue';
import axios from '@/plugins/axios'
import { useMessage } from '@/plugins/lib/useMessage'

export const useStudentRegister = () => {
  const { message } = useMessage();
  const formDatas      = reactive({ 'name': '', 'email': '','start_at': '', 'unlocked': true });
  const selectedCourse = ref('')
  const resetDatas = () => { 
    formDatas.name       = '';
    formDatas.email      = '';
    formDatas.start_at   = '';
    formDatas.unlocked   = true;
    selectedCourse.value = '';
  }

  const sendNewStudentData = async (parameter:Array<object>) => {
    try{
      const resp = await axios.post('student/register', { datas: parameter, course: selectedCourse.value });
      message('生徒を登録しました');
      return resp.data;  
    } catch (err) {
      if (err == 400) { message('生徒を登録できませんでした') }
    }
  }

  return { formDatas, selectedCourse, resetDatas, sendNewStudentData }
}