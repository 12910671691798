<template>
  <v-card class="ma-2">
    <v-sheet class="pa-2">
      <v-col v-if="err" cols="12" style="background-color: lightpink;">
        <v-row v-for="(val, key) in err" :key="key">
          <v-text>{{ val }}</v-text>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field label="新しいパスワード"
        v-model="password"
        :rules="[required,passwordLength]"
        counter="8"
        color="cyan-darken-1"
        prepend-icon="mdi mdi-lock-outline"
        type="password"
        clearable/>
        <v-text-field label="確認用"
          v-model="passwordConfirm"
          :rules="[required,passwordLength,matchCheck]"
          counter="8"
          color="cyan-darken-1"
          prepend-icon="mdi mdi-lock-check-outline"
          type="password"
          clearable/>
        <v-row justify="space-around" class="pa-2 ma-4">
          <v-btn @click="closeModal">閉じる</v-btn>
          <v-btn color="warning" @click="passwordsUpdate()">パスワード変更</v-btn>
        </v-row>
      </v-col>
    </v-sheet>
  </v-card>
</template>
<script setup>
import { ref, defineEmits } from 'vue';
import { useValidation } from '@/plugins/validation';
import { useAuthorization } from '@/plugins/apis/useAuthorization';
import { useFileReader } from '@/plugins/lib/admins/useFileReader';

const { required, passwordLength, validation } = useValidation();
const { password, passwordConfirm, passwordUpdate } = useAuthorization();
const { resetInputs } = useFileReader();

const emit = defineEmits(['toggleModal']);
const closeModal = () => {
  resetInputs();
  emit('toggleModal');
}

let err = ref();
const passwordsUpdate = async () => {
  let data = {
      'password':password.value,
      'passwordConfirm':passwordConfirm.value,
    }
  const validated = validation(data);
  if(validated == true){
    err.value = null;
    await passwordUpdate();
    resetInputs();
    emit('toggleModal');
  }else{
    err.value = validated;
  }
}

const matchCheck = (value) => value === password.value || 'パスワードが一致していません';

</script>