import { Ref, ref } from 'vue';

export const useQuestionData = () => {
    const questionData  = (datas:Object) => { return <Ref>ref(datas); }
    const switchMulti   = (selects_num:Number) => { return is_multipleR.value = selects_num == 1 ? false : true };
    const is_multipleR  = ref();

    return {
        questionData,
        is_multipleR,
        switchMulti
    }

}